<template>
  <div class="flex-col page">
    <div class="group_1">
      <div class="text_11">电梯</div>
      <div class="flex-row search">
        <img src="../../../assets/icon25.png" class="image_2" />
        <input type="text" class="text_1 text1" placeholder="请输入电梯代码或小区名称" v-model="searchInputValue"  @input="searchChange()"/>
      </div>
      <div class="group_2">
        <div>
          <span class="text_2" @click="selectHouses">{{showPicker}}</span>
          <img src="../../../assets/icon26.png" class="image_3" />
        </div>
        <div class="flex-row group_3">
          <div
            :class="['flex-col','items-center','center-text-wrapper',{'text-wrapper':index == tabId}]"
            @click="tabChange(index)"
            v-for="(item,index) in stateNav"
            :key="index"
          >
            <span>{{item}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col list">
      <div class="list-item justify-between" :key="i" v-for="(item, i) in list" @click="elevatorInfo(item.id)">
        <div class="group_4" >
          <div class="top-group">
            <div class="image-wrapper items-center">
              <img class="image_4" :src="item.icon" />
              <img class="image_14" v-if="item.state == '停运'" :src="item.warn" />
            </div>
          </div>
          <span class="text_6">{{item.state}}</span>
        </div>
        <div class="group_5">
          <span class="text_8">{{item.name}}</span>
          <span class="text_10">{{item.Dadd}}</span>
          <div class="group_6 flex-row">
            <img src="../../../assets/icon27.png" class="image_6" />
            <span class="text_12">注册代码：{{item.number}}</span>
          </div>
          <div class="group_6 flex-row">
            <img src="../../../assets/icon28.png" class="image_8" />
            <span class="text_14">所在小区：{{item.add}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {list} from '@/api/Property/Alarm/Alarm-list'
import { Info } from 'vant'
export default {
  data() {
    return {
      list: [], //电梯列表数组
      stateNav: ['全部', '正常', '故障'],//电梯状态导航
      showPicker: '全部区域',
      tabId:0,
      houses_id:'',
      searchInputValue:'',
      State:0,
    }
  },
  methods: {
    //切换状态
    tabChange(i) {
      this.tabId = i;
      this.list  = [];
      var  arr   = [ '' , 0 , 1 ];
      this.State = arr[i];
      this.initElevatorList();
    },
    selectHouses() {
      this.$router.push('/property/elevator/houses-select')
    },
    searchChange(){ 
       this.initElevatorList();
    },
    elevatorInfo(i){
      this.$router.push('/property/elevator/'+i)
    },
    initElevatorList(){
        this.list = [];
        list({status:this.State,text:this.searchInputValue,houses_id:this.houses_id}).then(res=>{

        let Data = res.data.Data.data;
        console.log(Data)
        if(res.data.Result == 200){
          for(var i = 0;i < Data.length; i++ ){
            if(Data[i].status == 0){
              this.list.push({
                id:Data[i].id,
                icon: require('../../../assets/icon30.png'),
                warn: require('../../../assets/icon31.png'),
                state: '正常',
                name:   Data[i].number,
                Dadd:   Data[i].address,
                number: Data[i].RegisterNumber,
                add:    Data[i].houses_name
              })
            }else if(Data[i].status == 1){
              this.list.push({
                id:Data[i].id,
                icon: require('../../../assets/icon29.png'),
                warn: require('../../../assets/icon31.png'),
                state: '停运',
                name: Data[i].number,
                Dadd:  Data[i].address,
                number: Data[i].RegisterNumber,
                add: Data[i].houses_name
              })
            }
          }
        }
      })
    }
  },
  mounted(){
     if(this.$route.params.id != 'id'){
        this.houses_id = this.$route.params.id
     }else{
        this.houses_id = ''
     }
    let HousesName =  sessionStorage.getItem('HousesName');
    if( HousesName && this.$route.params.id == 'id'){
      sessionStorage.removeItem('HousesName')
      this.showPicker = '全部区域'
    }else if(this.$route.params.id == 'id'){
      this.showPicker = '全部区域'
    }else if( HousesName && this.$route.params.id != 'id'){
      this.showPicker = HousesName
    }
    this.initElevatorList();
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  padding: 1.31rem 0 2.13rem;
  filter: drop-shadow(0px -0.19rem 0.75rem rgba(0, 0, 0, 0.05));
  border-radius: 1rem;
  position: relative;
}
.center-text-wrapper {
  margin-left: 0.63rem;
  flex: 1 1 3.75rem;
  color: rgb(102, 102, 102);
  font-size: 0.81rem;
  line-height: 0.81rem;
  white-space: nowrap;
  padding: 0.38rem 0 0.31rem;
  background: #fff;
  border-radius: 0.2rem;
  width: 3.75rem;
  height: 1.5rem;
}
.group_4 {
  margin: 0 1.19rem;
  color: rgb(0, 0, 0);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_5 {
  flex: 1;
  margin-left: 1rem;
}
.top-group {
  margin-bottom: 0.6rem;
}
.text_6 {
  margin-left: 0.88rem;
  margin-top: 0.63rem;
}
.text_8 {
  align-self: center;
  color: rgb(0, 0, 0);
  font-size: 0.94rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_10 {
  display: block;
  margin-right: 1.25rem;
  margin-top: 0.44rem;
  align-self: flex-end;
  color: rgb(139, 139, 139);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_6 {
  margin-right: 0.44rem;
  margin-top: 0.31rem;
  padding-top: 0.69rem;
  align-self: flex-end;
  color: rgb(153, 153, 153);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
  border-top: solid 0.063rem rgb(247, 247, 247);
}
.group_7 {
  margin-top: 0.75rem;
  padding: 0 5.19rem;
  color: rgb(153, 153, 153);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image-wrapper {
  padding: 0.94rem 0 0.063rem;
  filter: drop-shadow(0px 0.13rem 0.75rem rgba(0, 0, 0, 0.1));
  background: #fff;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image_6 {
  width: 0.75rem;
  height: 0.94rem;
}
.image_14 {
  position: absolute;
  right: -0.6rem;
  top: 0;
}
.text_12 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.image_8 {
  width: 0.81rem;
  height: 0.94rem;
}
.text_14 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.page {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  padding: 1rem 0.94rem 4rem;
  overflow: hidden;
  height: 11.13rem;
  position: relative;
  background: url(../../../assets/imgbg4.png) center top no-repeat;
  background-size: cover;
}
.list {
  margin-top: -1.3rem;
  padding-bottom: 8.13rem;
  position: relative;
  border-radius: 1rem;
  background: #fff;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.search {
  padding: 0.63rem 1rem;
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2rem;
}
.group_2 {
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 0.81rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.image_2 {
  width: 0.88rem;
  height: 0.88rem;
  margin-top: 0.2rem;
}
.text_1 {
  margin-left: 0.81rem;
  width: 88%;
  background: transparent;
  border: none;
  color: #fff;
}
.text_2 {
  margin-right: 0.22rem;
}
.text_11 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding-left: 1rem;
}
.image_3 {
  width: 0.31rem;
  height: 0.25rem;
}
.text-wrapper {
  padding: 0.38rem 0 0.31rem;
  flex: 1 1 3.75rem;
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  line-height: 0.81rem;
  white-space: nowrap;
  background: #024bb8;
  border-radius: 0.2rem;
  width: 3.75rem;
  height: 1.5rem;
}
.image_5 {
  width: 1.44rem;
  height: 1.31rem;
}
.image_4 {
  width: 2.06rem;
  height: 2.25rem;
}
.text1{
  color: #fff;
}
</style>

